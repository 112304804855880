import {
    DE,
    DE_AT,
    EN,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES,
    ES_ES,
    ES_US,
    FR,
    FR_BE,
    FR_FR,
    NL_NL,
    NL_BE,
} from '../constants/languageCode';

export const languageRedirections = (language) => {
    switch (language) {
        case DE_AT:
            return DE;
        case EN_AU:
            return EN;
        case EN_IN:
            return EN;
        case EN_SG:
            return EN;
        case EN_UK:
            return EN;
        case EN_US:
            return EN;
        case ES_US:
            return ES;
        case ES_ES:
            return ES;
        case FR_BE:
            return FR;
        case FR_FR:
            return FR;
        case NL_BE:
            return NL_NL;
        default:
            return '404';
    }
};
