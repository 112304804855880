import React from 'react';
import Layout from '../Layout/Layout';
import { Container, HeaderH1, HeaderH2, ErrorLink, LinkWrapper, MailLink } from './ErrorPageStyle';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import Header from '../HeaderDynamicPages/Header';

const ErrorPage = () => {
    const { t } = useTranslation();
    const { language } = useI18next();

    return (
        <Layout title={'404'}>
            <Header item={t('pageNotFound')} />
            <Container>
                <div>
                    <HeaderH1>{t('error404')}</HeaderH1>
                    <HeaderH2>{t('thePageYouRequestedDoesNotExist')}!</HeaderH2>
                    <p>{t('pleaseLetUsKnowAboutYourProblemByEmail')}</p>
                    <MailLink href="mailto:info@boge.de?subject=Error%20404&amp;body=Hallo!%20Ich%20konnte%20die%20Seite"></MailLink>
                    <LinkWrapper>
                        <ErrorLink to={`/${language}/`}>{t('goBackToFrontPage')}</ErrorLink>
                    </LinkWrapper>
                    <HeaderH2>{t('useOurInternalSearch')}</HeaderH2>
                    <LinkWrapper>
                        <ErrorLink to={`/${language}/search-site/`}>{t('bogeWebsiteSearch')}</ErrorLink>
                    </LinkWrapper>
                </div>
            </Container>
        </Layout>
    );
};

export default ErrorPage;
