import React, { useEffect } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { languageRedirections } from '../utils/languageRedirecitons';
import { graphql } from 'gatsby';
import ErrorPage from '../components/ErrorPage/ErrorPage';
import { redirectToMainPage } from '../utils/redirectToMainPage';
import { DE, GLOBAL } from '../constants/languageCode';

const NotFoundPage = () => {
    const { language, changeLanguage } = useI18next();

    useEffect(() => {
        if (language !== DE) {
            languageRedirections(language) !== '404' && changeLanguage(languageRedirections(language));
            if (language === GLOBAL) {
                redirectToMainPage(GLOBAL);
            }
        }
    }, [language]);

    if (language === GLOBAL) {
        return '';
    }

    return <ErrorPage />;
};

export default NotFoundPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
